import React, { useState } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Disclaimer_page from '../Disclaimer_page';

export default function Disclaimer_page_page() {

  return (
   <div >
    <Header/>
    <div className='py-5'><Disclaimer_page/></div>
   
   <Footer/>
   </div>
  );
}
