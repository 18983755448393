import React, { useState } from "react";
import Header from "./Header";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);
  };
  return (
    
    <div className="text-center home_sh home_sh_new bg-black" id="/">
     <div className="sticky hhh w-100"><Header /></div> 
      <h2 className="pb-4 pb-md-0 px-2 px-md-0">Dream. Plan. Move.</h2>
      <h1 className="px-2 px-md-0">We Make Migration Easy.</h1>
      <p className="px-2 px-md-0">
        Expert advice and tailored solutions to simplify
        <br className="d-none d-md-block" /> your migration journey.
      </p>

      <div className="d-block d-md-flex align-items-center justify-content-center btn_div_sh">
        <button className="get_free_btn mx-0 mx-md-2" onClick={() => handleNavigation("/contact-us")}>
          
            Get a Free Consultation
          
        </button>
        <button className="about_btn mx-0  mt-2 mt-lg-0 mx-md-2"onClick={() => handleNavigation("/about-us")}>
          
            About Us
          
        </button>
      </div>
    </div>
  );
}
