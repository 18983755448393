import React from "react";
export default function Privacypolicy() {
  return (
    <div className="privacy">
        <h1 className="text-center">Privacy Policy</h1>
        <h6 >Last updated: December 20, 2024</h6>
        <hr></hr>

        <div className="pb-3">
            <h4>Tawasol Relocation ("we," "us," or "our") respects your privacy and is committed to protecting the personal data you share with us. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our services or interact with our website</h4>
            <h4 className="pt-2">By using our services or providing your personal data to us, you agree to the terms outlined in this Privacy Policy.</h4>
        </div>

        <div className="d-flex align-items-center justify-content-center ">
            <ol>
                <li>We collect both personal and non-personal information when you interact with us. This may include</li>
                <ul>
                    <li><span>Personal Information: </span>Such as your full name, contact details (email, phone number), address, nationality, passport details, visa status, educational background, employment history, family details, and any other information you provide for the purpose of migration or visa applications.</li>
                    <li><span>Sensitive Information: </span>Depending on the nature of your application, we may collect sensitive information such as health data, criminal records, or financial details for processing migration or visa applications.</li>
                    <li><span>Technical Information: </span>This includes your IP address, browser type, device type, and information collected through cookies or similar tracking technologies when you visit our website.</li>    
                </ul>

                <li>How We Use Your Information</li>
                <p>We use the information we collect for the following purposes:</p>
                <ul>
                    <li><span>To provide migration consultancy services: </span>To assess your eligibility for various visa or migration programs, process applications, and provide advice or support related to your immigration needs.</li>
                    <li><span>To communicate with you: </span>To inform you of the status of your application, respond to your inquiries, and provide updates on relevant immigration policies or services.</li>
                    <li><span>To improve our services: </span>To analyse your interactions with us to enhance the quality and efficiency of our services.</li>
                    <li><span>For marketing purposes: </span>With your consent, we may use your contact information to send promotional materials, newsletters, or updates about our services. You may opt-out of marketing communications at any time.</li>
                    <li><span>To comply with legal obligations: </span>To fulfil regulatory and legal requirements related to immigration processes and documentation.</li>
                </ul>


                <li>Sharing Your Information</li>
                <p>We may share your personal data with the following third parties, where necessary:</p>
                <ul>
                    <li><span>Government and immigration authorities: </span>To process your visa or migration application with relevant immigration agencies, authorities, or legal bodies.</li>
                    <li><span>Service providers: </span>Third-party vendors who assist us with services like legal advice, document translation, data storage, or technical support, and who are bound by confidentiality agreements.</li>
                    <li><span>Other third parties: </span>If required by law, court order, or to protect our rights, we may disclose your personal data to legal authorities or other third parties.</li>
                </ul>
                <p >We will never sell, rent, or lease your personal data to third parties for marketing purposes without your explicit consent.</p>


                <li>Data Protection and Security</li>
                <p>We take reasonable steps to protect the security of your personal information. We employ a variety of security measures, including encryption, firewalls, and secure data storage practices to safeguard your data against unauthorized access, alteration, or disclosure.</p>
                <p>However, no system is completely secure, and we cannot guarantee the absolute security of your personal data.</p>

                <li>Cookies and Tracking Technologies</li>
                <p>We use cookies and similar technologies on our website to enhance your browsing experience and to gather data about how users interact with our site. Cookies help us remember your preferences, analyse website traffic, and personalize content. You can manage cookie settings through your browser, but disabling cookies may limit your ability to use certain features of our site.</p>


                <li>Your Rights</li>
                <p>Depending on applicable law, you may have certain rights regarding your personal data, including:</p>
                <ul>
                    <li><span>Access: </span>You can request a copy of the personal data we hold about you.</li>
                    <li><span>Correction: </span>You can request corrections to any inaccurate or incomplete personal data.</li>
                    <li><span>Deletion: </span>You may request that we delete your personal data, subject to legal and regulatory retention requirements.</li>
                    <li><span>Objection: </span>You can object to the processing of your personal data in certain circumstances.</li>
                    <li><span>Data Portability: </span>You may request to receive your personal data in a structured, commonly used format, and transfer it to another provider.</li>
                    <li><span>Withdrawal of Consent: </span>If we rely on your consent for processing your data, you can withdraw it at any time by contacting us.</li>
                </ul>
                <p >To exercise these rights, please contact us using the contact information provided below.</p>

                <li>Data Retention</li>
                <p>We will retain your personal data for as long as necessary to fulfil the purposes for which it was collected, including any legal or regulatory requirements. Once it is no longer necessary to retain your data, we will securely delete or anonymize it.</p>


                <li>Children's Privacy</li>
                <p>Our services are not intended for individuals under the age of 16, and we do not knowingly collect personal data from children. If we discover that we have collected personal information from a child under 16, we will take steps to delete it as soon as possible.</p>

                <li>Changes to This Privacy Policy</li>
                <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. When we make changes, we will update the "Effective Date" at the top of the page. We encourage you to periodically review this policy for the latest information on our privacy practices.</p>


                <li>Contact Us</li>
                <p>If you have any questions about this Privacy Policy, or if you wish to exercise any of your data protection rights, please contact us via +44 7412 827923.</p>
                
            </ol>


        </div>
        
      
    </div>
  );
}
