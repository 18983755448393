import React, { useEffect, useState } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import bg from '../../assets/portugal.png';

export default function Portugal() {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const navigate = useNavigate(); // Using the useNavigate hook
    
 
    useEffect(() => {
        // Create an image object to detect when the background image has loaded
        const img = new Image();
        img.src = bg;
        img.onload = () => setIsImageLoaded(true); // Set the state to true when the image loads
    }, []);

    // Function to handle navigation
    const handleNavigation = (path) => {
        navigate(path); // Navigate to the given path
    };

  return (
   <div className="image-loader-page">
            {!isImageLoaded ? (
                <div className="loading-placeholder">
                <div className="spinner"></div>
                <p>Loading...</p>
              </div>
              
            ) : ( <div className='text-center country_home country_03_home'>
                <div className='pb-5'> <Header/></div>
                 <div className='d-flex align-items-center justify-content-center country_visa_back_btn_div'>
                     
                     <button className='country_visa_back_btn'
                      onClick={() => navigate(-1)}><FaArrowLeft/>Go back</button>
                     
                 </div>
                 <h1 className='px-2'>Portugal Job Seeker Visa</h1>
                 <p className='px-2'>Allows you to search for employment in Portugal while enjoying the country's <br className='d-none d-lg-block'></br>vibrant culture and scenic landscapes. Ideal for skilled professionals.</p>
             
                 <div className='d-flex align-items-center justify-content-center'>
                 <Link to="start_reading"><button className='country_visa_start_btn mx-2'>Start Reading</button></Link>
                     
                 </div>
                </div>)}
  

   <div className='country_content' id='start_reading'>
    <div className='py-4'>
        <h3>Overview</h3>
        <p>This visa enables skilled professionals to explore career opportunities in Portugal while experiencing its vibrant culture and high quality of life.</p>
    </div>

    <div className='py-4'>
        <h3>Eligibility Criteria</h3>
        <ul>
            <li>Proof of professional qualifications or a recognized degree.</li>
            <li>Financial means to support your stay during the job search.</li>
            <li>A clean criminal record.</li>
        </ul>
    </div>

    <div className='py-4'>
        <h3>Key Benefits</h3>
        <ul>
            <li>Valid for up to 6 months to find a job.</li>
            <li>Opportunity to work in a country with a low cost of living and high safety index.</li>
            <li>Possibility to convert to a residence visa once employed.</li>
        </ul>
    </div>

    <div className='py-4'>
        <h3>How to Apply</h3>
        <p>Tawasol Relocation will guide you through every step, ensuring a smooth process.</p>
    </div>

    <div className='country_content_start_btn_div d-flex align-items-center justify-content-center d-md-block'><button onClick={() => handleNavigation("/contact-us")}>Contact Us Now</button></div>
   </div>
   <Footer/>
   </div>
  );
}
