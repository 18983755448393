import React, { useState } from 'react';
import Website from '../Website';
import Header from '../Header';
import Home from '../Home';
import Whoweare from '../Whoweare';
import Ourservices from '../Ourservices';
import Explore from '../Explore';
import Whychooseus from '../Whychooseus';
import Whatourclientsay from '../Whatourclientsay';
import Explorevisaprogram from '../Explorevisaprogram';
import Getintouch from '../Getintouch';
import Contact from '../Contact';
import Footer from '../Footer';

export default function Main_web() {

  return (
   <div >    
    <Home/>
    
   <Whoweare/>
   <Ourservices/>
   <Explore/>
   <Whychooseus/>
   {/* <Whatourclientsay/> */}
   <Explorevisaprogram/>
   <Getintouch/>
   <Contact/>
   <Footer/>
   </div>
  );
}
