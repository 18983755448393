import React, { useState, useEffect } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import bg from '../../assets/germany.png';

export default function German() {
    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const navigate = useNavigate(); // Using the useNavigate hook
    
 
    useEffect(() => {
        // Create an image object to detect when the background image has loaded
        const img = new Image();
        img.src = bg;
        img.onload = () => setIsImageLoaded(true); // Set the state to true when the image loads
    }, []);

    // Function to handle navigation
    const handleNavigation = (path) => {
        navigate(path); // Navigate to the given path
    };

    return (
        <div className="image-loader-page">
            {!isImageLoaded ? (
                <div className="loading-placeholder">
                <div className="spinner"></div>
                <p>Loading...</p>
              </div>
              
            ) : (
                <div
                    className='text-center country_home country_02_home'
                    style={{ backgroundImage: `url(${bg})` }}
                >
                    <div className='pb-5'>
                        <Header />
                    </div>
                    <div className='d-flex align-items-center justify-content-center country_visa_back_btn_div'>
                        <button
                            className='country_visa_back_btn'
                            onClick={() => navigate(-1)}
                        >
                            <FaArrowLeft /> Go back
                        </button>
                    </div>
                    <h1 className='px-2'>Germany Opportunity Card</h1>
                    <p className='px-2'>
                        A flexible visa option for skilled workers, offering a chance to explore job
                        <br className='d-none d-lg-block'></br>
                        opportunities in Germany while meeting the country's economic demands.
                    </p>

                    <div className='d-flex align-items-center justify-content-center'>
                        <Link to="start_reading">
                            <button className='country_visa_start_btn mx-2'>Start Reading</button>
                        </Link>
                    </div>
                </div>
            )}

            <div className='country_content' id='start_reading'>
                <div className='py-4'>
                    <h3>Overview</h3>
                    <p>
                        The Opportunity Card is tailored for skilled workers aiming to explore job
                        opportunities in Germany. This visa provides flexibility and a chance to integrate
                        into Germany's thriving labor market.
                    </p>
                </div>

                <div className='py-4'>
                    <h3>Eligibility Criteria</h3>
                    <ul>
                        <li>Minimum professional qualifications or a university degree.</li>
                        <li>Basic knowledge of German or English.</li>
                        <li>Proof of financial stability during your job search.</li>
                    </ul>
                </div>

                <div className='py-4'>
                    <h3>Key Benefits</h3>
                    <ul>
                        <li>Up to 6 months to search for employment in Germany.</li>
                        <li>Access to one of the world's strongest economies.</li>
                        <li>Opportunity to transition to a work visa after securing a job.</li>
                    </ul>
                </div>

                <div className='py-4'>
                    <h3>How to Apply</h3>
                    <p>
                        With Tawasol Relocation, you'll get complete assistance, from document
                        preparation to submission.
                    </p>
                </div>

                <div className='country_content_start_btn_div d-flex align-items-center justify-content-center d-md-block'>
                    <button onClick={() => handleNavigation("/contact-us")}>
                        Contact Us Now
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    );
}
