import React from "react";
export default function Disclaimer_page() {
  return (
    <div className="privacy">
        <h1 className="text-center">Disclaimer</h1>
        <h6>Last updated: December 20, 2024</h6>
        <hr></hr>

        <div className="pb-3">
            <h4>By accessing and using the services and content provided by Tawasol Relocation ("we," "us," or "our"), you acknowledge and agree to the following terms of this Disclaimer. If you do not agree with this Disclaimer, you must not use our services or website.</h4>
            
        </div>

        <div>
            <ol>
                <li>No Guarantee of Success</li>
                <p>While we strive to provide accurate and reliable immigration advice and support, Tawasol Relocation does not guarantee the success or approval of any visa, immigration, or residency application. Immigration decisions are made solely by government agencies and authorities, and we cannot influence or predict the outcome of any application. We provide services to assist you with the process, but the final decision lies with the relevant authorities.</p>


                <li>General Information</li>
                <p>The information provided on our website and through our consultancy services is intended for general informational purposes only. While we make every effort to ensure the information is accurate and up-to-date, we do not guarantee its accuracy, completeness, or timeliness. Immigration laws and policies are subject to change, and we recommend that you consult with relevant government agencies or legal professionals for the most current and personalized advice.</p>


                <li>No Legal Advice</li>
                <p>Tawasol Relocation is a migration consultancy firm and not a law firm. Our services include advice and assistance regarding migration programs and procedures, but we do not offer legal services. We strongly recommend that you seek independent legal advice from a qualified immigration lawyer if you need assistance with legal matters or representation before immigration authorities.</p>


                <li>Responsibility for Accuracy</li>
                <p>You are responsible for ensuring that all information and documents provided to us are complete, accurate, and up to date. We are not liable for any delays, rejections, or complications that arise due to inaccurate or incomplete information provided by you. While we make every effort to assist you in submitting accurate applications, the ultimate responsibility lies with you to ensure the accuracy of all information submitted to relevant authorities.</p>


                <li>Third-Party Links and Content</li>
                <p>Our website or services may contain links to third-party websites, including government agencies, immigration authorities, or other service providers. We do not endorse, control, or take responsibility for the content or practices of any third-party websites. We recommend reviewing the privacy policies and terms of use of third-party sites before interacting with them.</p>

                <li>Limitation of Liability</li>
                <p>To the maximum extent permitted by law, Tawasol Relocation is not liable for any direct, indirect, incidental, special, consequential, or punitive damages arising from your use of our services, including, but not limited to, any errors or omissions in the information provided, delays in processing, or failure to obtain a visa or immigration status.</p>
                <p>We do not guarantee the accuracy, completeness, or effectiveness of any advice or service provided and are not responsible for any costs, losses, or damages you may incur as a result of using our services.</p>


                <li>Changes to Services and Terms</li>
                <p>We reserve the right to modify, suspend, or discontinue any aspect of our services at any time without notice. We may also update this Disclaimer periodically to reflect changes in our practices, services, or applicable laws. The updated Disclaimer will be effective immediately upon posting, and you are encouraged to review this page regularly.</p>


                <li>Indemnification</li>
                <p>You agree to indemnify and hold harmless Tawasol Relocation and its employees, agents, affiliates, and partners from any claims, liabilities, damages, losses, or expenses (including legal fees) arising from your use of our services, your failure to comply with this Disclaimer, or any third-party claims resulting from your actions.</p>

                <li>Governing Law</li>
                <p>This Disclaimer is governed by and construed in accordance with the laws of the United Kingdom, and any disputes arising from this Disclaimer shall be resolved in the courts of the UK.</p>


                <li>Contact Us</li>
                <p>If you have any questions regarding this Disclaimer, please contact us via +44 7412 827923</p>
            </ol>


        </div>
        
      
    </div>
  );
}
