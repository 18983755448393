import React, { useState } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Terms_and_condition from '../Terms_and_condition';

export default function Terms_and_condition_page() {

  return (
   <div >
    <Header/>
    <div className='py-5'><Terms_and_condition/></div>
   <Footer/>
   </div>
  );
}
