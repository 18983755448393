import React, { useState } from 'react';
import Footer from '../Footer';
import Header from '../Header';
import Privacypolicy from '../Privacypolicy';

export default function Privacy_policy_page() {

  return (
   <div >
    <Header/>
    <div className='py-5'><Privacypolicy/></div>
   <Footer/>
   </div>
  );
}
