import React, { useState } from "react";
import contact from "../assets/contact.png";
import { Modal, Spinner } from "react-bootstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import './modalContent.css';

export default function Contact() {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [inquiry, setInquiry] = useState("");
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [showModal,setShowModal]= useState(false);



 
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formData = {
      name,
      email,
      phone,
      inquiry,
      message,
    };

    try {
      const response = await axios.post(`https://tawasolmigration.com/api_tawasol/send_email.php`,
        formData
      );
      setShowModal(true);
      console.log("response.data", response.data);

      setName('');
      setEmail('');
      setPhone('');
      setInquiry('');
      setMessage('');

    } catch (error) {
      console.error("Failed to send email:", error);
    } finally {
      setLoading(false);
    }

    console.log(formData);
  };

  const handleContinue = () => {
    setShowModal(false);

  };

  return (
    <div className="d-flex align-items-center justify-content-center">
      <div className="contactform_div my-0 my-lg-5">
        <div className="contact_form_heading">
          <h2 className="mb-2 mb-lg-4">Contact Us</h2>
          <p>Ready to take the first step toward your new future? We're here to help!</p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label htmlFor="name" className="form-label">
              Name<span className="text-danger">*</span>
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="email" className="form-label">
              Email<span className="text-danger">*</span>
            </label>
            <input
              type="email"
              className="form-control"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="phone" className="form-label">
              Phone<span className="text-danger">*</span>
            </label>
            <input
              type="tel"
              className="form-control"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="inquiry" className="form-label">
              Type of Inquiry<span className="text-danger">*</span>
            </label>
            <select
              className="form-select"
              id="inquiry"
              value={inquiry}
              onChange={(e) => setInquiry(e.target.value)}
              required
            >
              <option value="" disabled>Select an option</option>
              <option value="europe">Europe Visa Program</option>
              <option value="luxembourg">Luxembourg Visa Program</option>              
              <option value="german">German Visa Program</option>
              <option value="portugal">Portugal Visa Program</option>              
              <option value="wweden">Sweden Visa Program</option>
            </select>
          </div>

          <div className="mb-3">
            <label htmlFor="message" className="form-label">
              Message<span className="text-danger">*</span>
            </label>
            <textarea
              className="form-control"
              id="message"
              rows="4"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
          </div>

          
          <button type="submit" className="contact_btn" disabled={loading}>
        {loading ? "Sending..." : "Submit Inquiry"}
      </button>
          
        </form>
      </div>

      <div className="w-50 contact_img_div d-none d-lg-block">
        <img
          src={contact}
          alt="Contact Us"
          className="img-fluid"
        />
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} className="modal_main">
        <h2 className="text-center pt-4 box_content_heading">Message sent!</h2>
        <Modal.Body>
          <div className="box_content px-2">
          {/* <div  className="d-flex col-12 align-items-center justify-content-center"> <img src={celebration} style={{width: "110px", height: "110px"}} alt="Celebration"/></div> */}
            <p className="phra">
            Thank you for reaching out to us! We understand your time is valuable, and we promise to get back to you within 1-2 business days.
            </p>
            
          </div>
        </Modal.Body>
        <div className="px-4 pt-3 pb-4">
          <button className="col-12 box_btn" onClick={handleContinue}>Done</button>
        </div>
      </Modal>
    </div>
  );
}