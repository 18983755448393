import React from "react";
import { IoReorderThreeOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.svg";
import whatsapp from "../assets/whatsapp.svg";
import { Collapse } from 'bootstrap';

export default function Header() {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    navigate(path);

    // Close the navbar on navigation
    const navbarToggler = document.querySelector(".navbar-collapse");
    if (navbarToggler && navbarToggler.classList.contains("show")) {
      const bsCollapse = new Collapse(navbarToggler, {
        toggle: false,
      });
      bsCollapse.hide();
    }
  };

  const toggleNavbar = () => {
    const navbarToggler = document.querySelector(".navbar-collapse");
    if (navbarToggler) {
      const bsCollapse = new Collapse(navbarToggler, {
        toggle: false,
      });
      if (navbarToggler.classList.contains("show")) {
        bsCollapse.hide();
      } else {
        bsCollapse.show();
      }
    }
  };

  return (
    <header className="px-0 px-md-4 header_shh">
      <nav className="navbar navbar-expand-lg px-1 px-md-4 nav_sh">
        <div className="container-fluid">
          {/* Logo Section */}
          <div
            className="img_cls_sh navbar-brand d-inline-block align-center"
            onClick={() => handleNavigation("/")}
          >
            <img src={logo} alt="Company Logo" width="52px" height="57px" />
          </div>

          {/* WhatsApp Section */}
          <div className="d-flex align-items-center whatsapp-number-div whatsapp_sh d-block d-lg-none">
            <a
              href="https://wa.me/447412827923?text=Hello%2C%20I%20would%20like%20more%20information%20about%20your%20services."
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoration-none"
            >
              <img
                src={whatsapp}
                alt="WhatsApp Logo"
                width="30px"
                height="30px"
                className="d-inline-block align-center me-2"
              />
            </a>
            <a
              href="https://wa.me/447412827923?text=Hello%2C%20I%20would%20like%20more%20information%20about%20your%20services."
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoration-none"
            >
              WhatsApp Now
            </a>
          </div>

          {/* Toggle Button for Mobile */}
          <button
            className="navbar-toggler text-white"
            type="button"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={toggleNavbar}
          >
            <IoReorderThreeOutline size={30} />
          </button>

          {/* Navbar Links */}
          <div
            className="collapse navbar-collapse justify-content-center navbar_sh"
            id="navbarNav"
          >
            <ul className="navbar-nav">
              <li className="nav-item">
                <div
                  className="Link_cls"
                  onClick={() => handleNavigation("/")}
                >
                  Home
                </div>
              </li>
              <li className="nav-item">
                <div
                  className="Link_cls"
                  onClick={() => handleNavigation("/about-us")}
                >
                  About Us
                </div>
              </li>
              <li className="nav-item">
                <div
                  className="Link_cls"
                  onClick={() => handleNavigation("/service")}
                >
                  Services
                </div>
              </li>
              <li className="nav-item">
                <div
                  className="Link_cls"
                  onClick={() => handleNavigation("/why-choose-us")}
                >
                  Why Choose Us
                </div>
              </li>
              {/* <li className="nav-item">
                <div
                  className="Link_cls"
                  onClick={() => handleNavigation("/testimonials")}
                >
                  Testimonials
                </div>
              </li> */}
              <li className="nav-item">
                <div
                  className="Link_cls"
                  onClick={() => handleNavigation("/visaprogram")}
                >
                  Visa Programs
                </div>
              </li>
              <li className="nav-item">
                <div
                  className="Link_cls"
                  onClick={() => handleNavigation("/contact-us")}
                >
                  Contact Us
                </div>
              </li>
            </ul>
          </div>

          {/* WhatsApp Section */}
          <div className="d-flex align-items-center whatsapp-number-div whatsapp_sh d-none d-lg-block">
            <img
              src={whatsapp}
              alt="WhatsApp Logo"
              width="18px"
              height="18px"
              className="d-inline-block align-center me-2"
            />
            <a
              href="https://wa.me/447412827923?text=Hello%2C%20I%20would%20like%20more%20information%20about%20your%20services."
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoration-none"
            >
              WhatsApp Now
            </a>
          </div>
        </div>
      </nav>
    </header>
  );
}
