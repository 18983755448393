import React from "react";
export default function Terms_and_condition() {
  return (
    <div className="privacy">
        <h1 className="text-center">Terms and Conditions</h1>
        <h6>Last updated: December 20, 2024</h6>
        <hr></hr>

        <div className="pb-3">
            <h4>These Terms and Conditions ("Terms") govern the use of the services provided by Tawasol Relocation ("we," "us," or "our") and your use of our website, products, and services (collectively, "Services"). By accessing or using our Services, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, you must not use our Services.</h4>
        </div>

        <div>
            <ol>
                <li>Services Provided</li>
                <p>We offer migration consultancy services, including but not limited to:</p>
                <ul>
                    <li>Visa and immigration advice and assistance</li>
                    <li>Documentation preparation and submission</li>
                    <li>Eligibility assessment for various immigration programs</li>
                    <li>Support for work, study, family reunification, or residency applications</li>
                </ul>
                <p >Our Services are intended to help individuals navigate the immigration process and should not be considered as a guarantee for approval or successful immigration outcomes</p>


                <li>Eligibility</li>
                <p>To use our Services, you must be at least 18 years of age and legally capable of entering into a binding contract. If you are under the age of 18, you must have consent from a parent or guardian to use our Services.</p>

                <li>Fees and Payment</li>
                <p>Our Services may be subject to fees, which will be communicated to you at the time-of-service request or when you enter into an agreement with us. All fees are non-refundable unless otherwise specified.</p>
                <p>Payment for our Services must be made in accordance with the payment methods and terms provided at the time-of-service engagement</p>

                <li>Your Responsibilities</li>
                <p>You agree to:</p>
                <ul>
                    <li>Provide accurate and complete information to assist us in delivering our Services.</li>
                    <li>Cooperate with us and follow any instructions or requests we provide regarding your immigration application or visa process.</li>
                    <li>Acknowledge that the success of your application is not guaranteed, and that we cannot be held liable for any negative outcome, delays, or rejections by immigration authorities</li>
                </ul>


                <li>Confidentiality and Privacy</li>
                <p>We value your privacy and handle your personal information in accordance with our Privacy Policy. We will not disclose your personal data to third parties without your consent, except where required by law or necessary to provide our Services.</p>


                <li>Intellectual Property</li>
                <p>All content, materials, and intellectual property on our website, including but not limited to text, logos, images, designs, and software, are the property of Tawasol Relocation and are protected by copyright, trademark, and other intellectual property laws.</p>
                <p>You may not reproduce, distribute, or use any content from our website without prior written consent.</p>

                <li>Limitation of Liability</li>
                <p>To the fullest extent permitted by law, Tawasol Relocation will not be liable for any indirect, incidental, special, or consequential damages, or for any loss of profits, data, or business arising from your use or inability to use our Services, even if we have been advised of the possibility of such damages. </p>
                <p>We will not be liable for any delay or failure in the performance of our obligations due to circumstances beyond our control, such as acts of government, natural disasters, or other unforeseen events.</p>


                <li>Termination</li>
                <p>We reserve the right to suspend or terminate your access to our Services, at our discretion, for any reason, including if you violate these Terms or fail to provide the necessary documentation or information for your migration application.</p>


                <li>Amendments to the Terms</li>
                <p>We may update or amend these Terms from time to time to reflect changes in our practices, legal requirements, or for other operational reasons. Any changes will be effective immediately upon posting on our website, and you will be deemed to have accepted the changes by continuing to use our Services.</p>


                <li>Governing Law</li>
                <p>These Terms shall be governed by and construed in accordance with the laws of the United Kingdom, without regard to its conflict of law principles. Any disputes arising from or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts located in the UK.</p>


                <li>Dispute Resolution</li>
                <p>If you have a dispute with us, you agree to attempt to resolve it informally by contacting us directly. If the dispute cannot be resolved informally, it will be submitted to binding arbitration or other alternative dispute resolution methods as specified in your location or per the governing laws.</p>


                <li>Indemnity</li>
                <p>You agree to indemnify, defend, and hold harmless Tawasol Relocation and its employees, agents, and affiliates from and against any claims, liabilities, damages, losses, or expenses (including legal fees) arising from your use of our Services, violation of these Terms, or any unlawful act or omission by you.</p>
                

                <li>Severability</li>
                <p>If any part of these Terms is deemed invalid or unenforceable under applicable law, the remainder of the Terms will continue in full force and effect. The invalid or unenforceable provision will be modified to the extent necessary to make it valid and enforceable.</p>


                <li>Contact Us</li>
                <p>If you have any questions or concerns about these Terms, or if you wish to contact us for any reason, please reach out to us via +44 7412 827923</p>
            </ol>


        </div>
        
      
    </div>
  );
}
