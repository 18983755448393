import React, { useEffect, useState } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-scroll';
import bg from '../../assets/luxembourg.png';


export default function Luxembourg() {

    const [isImageLoaded, setIsImageLoaded] = useState(false);
    const navigate = useNavigate(); // Using the useNavigate hook
    
 
    useEffect(() => {
        // Create an image object to detect when the background image has loaded
        const img = new Image();
        img.src = bg;
        img.onload = () => setIsImageLoaded(true); // Set the state to true when the image loads
    }, []);

    // Function to handle navigation
    const handleNavigation = (path) => {
        navigate(path); // Navigate to the given path
    };
  return (
    <div className="image-loader-page">
    {!isImageLoaded ? (
        <div className="loading-placeholder">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
      
    ) : (
        <div className='text-center country_home country_01_home'>
   <div className='pb-5'> <Header/></div>
    <div className='d-flex align-items-center justify-content-center country_visa_back_btn_div'>
        
        <button className='country_visa_back_btn'
         onClick={() => navigate(-1)}><FaArrowLeft/>Go back</button>
        
    </div>
    <h1 className='px-2'>Luxembourg EU Blue Card</h1>
    <p className='px-2'>Designed for highly skilled professionals, this visa provides an opportunity to live <br className='d-none d-lg-block'></br>and work in Luxembourg with attractive benefits and pathways to residency.</p>

    <div className='d-flex align-items-center justify-content-center'>
    <Link to="start_reading"><button className='country_visa_start_btn mx-2'>Start Reading</button></Link>
        
    </div>
   </div>
      )}
   

   <div className='country_content' id='start_reading'>
    <div className='py-4'>
        <h3>Overview</h3>
        <p>The EU Blue Card is designed for highly skilled professionals seeking to live and work in Luxembourg. It offers an attractive pathway to residency, family unification, and professional growth in one of Europe's wealthiest nations.</p>
    </div>

    <div className='py-4'>
        <h3>Eligibility Criteria</h3>
        <ul>
            <li>A recognized higher education qualification (bachelor's degree or higher).</li>
            <li>A valid job offer in Luxembourg with a salary above the set threshold.</li>
            <li>Proof of health insurance coverage.</li>
        </ul>
    </div>

    <div className='py-4'>
        <h3>Key Benefits</h3>
        <ul>
            <li>High salary packages and job stability.</li>
            <li>Family reunification with the same rights as the cardholder.</li>
            <li>Fast-track to permanent residency after 33 months.</li>
            <li>Access to Luxembourg's robust social and healthcare systems.</li>
        </ul>
    </div>

    <div className='py-4'>
        <h3>How to Apply</h3>
        <p>Step-by-step guidance will be provided by Tawasol Relocation, ensuring you meet all requirements and deadlines.</p>
    </div>

    <div className='country_content_start_btn_div d-flex align-items-center justify-content-center d-md-block'><button onClick={() => handleNavigation("/contact-us")}>Contact Us Now</button></div>
   </div>
   <Footer/>
   </div>
  );
}
